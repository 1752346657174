
import { Component } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import { IUser } from "@/types/user";
import { IShipment, IShipmentListRequest } from "@/types/shipment";
import { IOrder, IOrderListRequest } from "@/types/order";
import { IProduct, IProductListRequest } from "@/types/product";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  @Getter("auth/me")
  public user!: IUser;

  @Getter("isLoading")
  public isLoading!: boolean;

  // shipment
  @Action("shipment/adminGetList")
  public getShipmentList!: (request: IShipmentListRequest) => Promise<boolean>;

  @Getter("shipment/filterShipmentSchedule")
  public shipmentList!: IShipment[];

  @Mutation("shipment/clear")
  public clearShipments!: () => void;

  // order
  @Action("order/adminGetList")
  public getOrderList!: (request: IOrderListRequest) => Promise<boolean>;

  @Getter("order/filterOrderSchedule")
  public orderList!: IOrder[];

  @Mutation("order/clear")
  public clearOrders!: () => void;

  //product
  @Action("product/adminGetList")
  public getProductList!: (request: IProductListRequest) => Promise<boolean>;

  @Getter("product/list")
  public productList!: IProduct[];

  @Getter("product/alertList")
  public alertProductList!: IProduct[];

  @Mutation("product/clear")
  public clearProducts!: () => void;

  //
  // variables
  //
  public menu = {
    shipment_date: false,
    start: false,
    end: false,
    day: false
  };
  public start_date = "";
  public end_date = "";
  public shipment_params: IShipmentListRequest = {
    search_span_start: "",
    search_span_end: "",
    per_page: 0
  };
  public order_params: IOrderListRequest = {
    per_page: 0,
    sort_date_column: "fixed_delivery_date"
  };

  public shipments_headers = [
    {
      text: "出荷日",
      value: "shipment_date",
      sortable: false,
      align: "center"
    },
    {
      text: "出荷状況",
      value: "shipment_status",
      sortable: false,
      align: "center"
    },
    {
      text: "顧客",
      value: "customer_short_name",
      sortable: false,
      align: "center"
    },
    { text: "支店", value: "branch_name", sortable: false, align: "center" },
    {
      text: "顧客注文番号",
      value: "received_order_no",
      sortable: false,
      align: "center"
    }
  ];

  public orders_headers = [
    {
      text: "入荷予定日",
      value: "fixed_delivery_date",
      sortable: false,
      align: "center"
    },
    { text: "状況", value: "order_status", sortable: false, align: "center" },
    {
      text: "発注番号",
      value: "order_no",
      sortable: false,
      align: "center"
    },
    { text: "備考", value: "remark", sortable: false, align: "center" },
    {
      text: "発注商品メモ",
      value: "content",
      sortable: false,
      align: "center"
    }
  ];

  public async created() {
    this.clearShipments();
    this.clearOrders();
    this.clearProducts();

    this.setDefaultDate();

    await Promise.all([
      this.getShipmentList(this.shipment_params),
      this.getOrderList(this.order_params),
      this.getProductList({ per_page: 0 })
    ]);
  }

  // 入荷予定リスト編集
  get setOrderList() {
    return this.orderList.map(order => {
      const is_memo = order.order_product.some(v => !!v.content);
      if (is_memo) {
        return {
          id: order.id,
          fixed_delivery_date: order.fixed_delivery_date,
          order_status: order.order_status,
          order_no: order.order_no,
          remark: order.remark,
          is_memo: true
        };
      }
      return {
        id: order.id,
        fixed_delivery_date: order.fixed_delivery_date,
        order_status: order.order_status,
        order_no: order.order_no,
        remark: order.remark,
        is_memo: false
      };
    });
  }

  // 今日と１週間後の日付をセット
  public setDefaultDate() {
    // 今日を取得
    const today = new Date();
    const fixed_start_date =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2);

    // １週間後を取得
    let nextWeekDay = new Date();
    nextWeekDay.setDate(nextWeekDay.getDate() + 7);
    const fixed_end_date =
      nextWeekDay.getFullYear() +
      "-" +
      ("0" + (nextWeekDay.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + nextWeekDay.getDate()).slice(-2);

    this.start_date =
      this.shipment_params.search_span_start =
      this.order_params.search_span_start =
        fixed_start_date;
    this.end_date =
      this.order_params.search_span_end =
      this.shipment_params.search_span_end =
        fixed_end_date;
  }

  // 終了日が再選択されたとき
  public setFixedEndDate() {
    this.order_params.search_span_end = this.shipment_params.search_span_end =
      this.end_date;
    this.getShipmentList(this.shipment_params);
    this.getOrderList(this.order_params);
  }

  // バリデーション
  public endDateRule() {
    if (this.start_date > this.end_date) {
      return "正しい日付を入力してください";
    }

    return true;
  }

  // 日付選択範囲の指定
  public allowedDate(val: string) {
    let today = new Date();
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    let max_allowed_day = new Date();
    max_allowed_day = new Date(
      max_allowed_day.getFullYear(),
      max_allowed_day.getMonth() + 1,
      max_allowed_day.getDate()
    );
    return today <= new Date(val) && new Date(val) <= max_allowed_day;
  }

  // 出荷データをクリック
  public shipmentRowClick(shipment: IShipment) {
    this.$router.push(`/admin/shipment/${shipment.id}`);
  }

  // 入荷データをクリック
  public orderRowClick(order: IOrder) {
    this.$router.push(`/admin/order/${order.id}`);
  }

  // 在庫アラート確認ボタンをクリック
  public alertInventoryClick() {
    this.$router.push(`/admin/inventory/alert_list`);
  }
}
